<script>
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/vue/20/solid'

export default {
  name: 'AppDialogMessage',
  components: { ExclamationCircleIcon, CheckCircleIcon },
  props: {
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    icon () {
      return this.type === 'error' ? 'ExclamationCircleIcon' : 'CheckCircleIcon'
    },
    style () {
      return this.type === 'error'
        ? 'border-red-600 bg-red-50 text-red-700 dark:bg-transparent dark:text-red-500'
        : 'border-green-600 bg-green-50 text-green-700 dark:bg-transparent dark:text-green-500'
    }
  }
}
</script>

<template>
  <p :class="style" class="border rounded-lg p-2 font-semibold my-2 gap-x-2 flex">
    <component :is="icon" class="shrink-0 w-5 h-5 inline mt-1" />
    {{ message }}
  </p>
</template>

<style scoped>

</style>
