<script setup>
const props = defineProps({
  baseClasses: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'text'
  },
  autocomplete: {
    type: String,
    default: ''
  },
  required: {
    type: Boolean,
    default: false
  },
  autofocus: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: String,
    default: ''
  },
  errors: {
    type: Array,
    default: []
  },
  enableValidation: {
    type: Boolean,
    default: true
  }
})

const input = ref();
const overwriteErrorState = ref(false)

// make computed that checks if errors contains an object with field name that equals the props.name
const errorMessage = computed(() => {
  overwriteErrorState.value = false
  const error = props.errors.find(error => error.name === props.name)

  // grab the ref with the name of the input field, then get the original html element and use .setCustomValidity("dummy value");
  // to trigger the browser's native validation
  if(error) {
    input.value.setCustomValidity(error.message)
  }

  return error ? error.message : ''
})

// watch modelValue and if it changes, remove the custom validity
watch(() => props.modelValue, () => {
  if(input.value) {
    input.value.setCustomValidity('')
    overwriteErrorState.value = true
  }
})

defineEmits(['update:modelValue', 'enter'])
</script>

<template>
  <div :class="baseClasses">
    <label v-if="label !== ''" :for="name" class="block text-sm font-medium leading-6 text-gray-900 dark:text-white">{{ label }}<span v-if="required" class="text-secondary pl-0.5">*</span></label>
    <div :class="label ? 'mt-2' : ''">
      <input :id="name" :value="modelValue" :name="name" :type="type" :autofocus="autofocus" :disabled="disabled" :autocomplete="autocomplete" :required="required" :placeholder="placeholder"
             class="block w-full rounded-md border-0 py-1.5 ring-1 ring-inset ring-gray-300 bg-white dark:ring-white/10 dark:bg-white/5 text-gray-900 dark:text-white focus:ring-2 focus:ring-primary disabled:text-gray-500 disabled:cursor-not-allowed dark:disabled:text-gray-400 sm:text-sm sm:leading-6"
             :class="enableValidation ? 'invalid:ring-0 invalid:border invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500' : ''"
             @input="$emit('update:modelValue', $event.target.value)" @keyup.enter="$emit('enter')" ref="input"
      >
      <p v-if="errorMessage && !overwriteErrorState" class="block text-xs mt-1 text-red-600 font-semibold">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<style scoped>

</style>
